import React, { useContext } from "react";
import TokenInput from "../../../component/TokenInput";
import Context from "./context/Context";

export default function Step1() {
  const { value, btnNextStep, setValue } = useContext(Context);

  const firstStepSubmit = (e) => {
    if (
      value.tokenName !== "" ||
      value.tokenDecimal !== "" ||
      value.tokenSymbol !== ""
    ) {
      btnNextStep(e);
    } else {
    }
  };

  return (
    <div
      className={`tab-pane ${value.step === 1 ? "active" : ""}`}
      role="tabpanel"
      id="step1"
    >
      <h4 className="text-center">Enter the token address and verify</h4>
      <div className="row">
        <TokenInput value={value} setValue={setValue} />
      </div>
      <ul className="list-inline text-center">
        <li>
          
          <button
            type="button"
            className="btn default-btn next-step"
            onClick={(e) => firstStepSubmit(e)}
          >
            Continue to next step
          </button>
        </li>
      </ul>
    </div>
  );
}
