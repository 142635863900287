import React, { useEffect, useMemo, useState } from "react";
import { useAccountStats, useCommonStats } from "./helper/useStats";
import Countdown, { zeroPad } from "react-countdown";
import { useWeb3React } from "@web3-react/core";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap-button-loader";
import { formatPrice } from "../../../hooks/contractHelper";
import poolAbi from "../../../json/Airdrop.json";
import ERC20Abi from "../../../json/ERC20.json";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { toast } from "react-toastify";
import { getWeb3 } from "../../../hooks/connectors";
import { getContract } from "../../../hooks/contractHelper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { formatAddress } from "../../../utils/helper";
import question from "../../../images/question.png";

export default function ProjectDetails() {
  const [updater, setUpdater] = useState(1);
  const stats = useCommonStats(updater);
  const accStats = useAccountStats(updater);
  const [social, setSocial] = useState({});
  const { chainId, account, library } = useWeb3React();
  const [refcopy1, setRefcopy1] = useState(false);
  const [refcopy2, setRefcopy2] = useState(false);

  const [editSocial, setEditSocial] = useState({
    logourl: "",
    bannerurl: "",
    website: "",
    blockstart: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
  });

  const [isValidLogoUrl, setIsValidLogoUrl] = useState(true);

  const handleLoadLogo = () => {
    setIsValidLogoUrl(true);
  };

  const handleErrorLogo = () => {
    setIsValidLogoUrl(false);
  };

  useEffect(() => {
    function getDetails() {
      let details = stats.poolDetails.toString().split("$#$");
      const object = {
        logourl: details[0],
        bannerurl: details[1],
        website: details[2],
        blockstar: details[3],
        facebook: details[4],
        twitter: details[5],
        github: details[6],
        telegram: details[7],
        instagram: details[8],
        discord: details[9],
        reddit: details[10],
        youtube: details[11],
        brief: details[12],
      };

      setSocial(object);
      setEditSocial(object);
    }
    if (stats) {
      getDetails();
    }
  }, [stats]);

  const countdownrender = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <ul>
          <li>
            <pre>00 D : </pre>
          </li>
          <li>
            <pre>00 H : </pre>
          </li>
          <li>
            <pre>00 M : </pre>
          </li>
          <li>
            <pre>00 S</pre>
          </li>
        </ul>
      );
    } else {
      // Render a countdown
      return (
        <ul>
          <li>
            <pre>{zeroPad(days, 2)} D : </pre>
          </li>
          <li>
            <pre>{zeroPad(hours, 2)} H : </pre>
          </li>
          <li>
            <pre>{zeroPad(minutes, 2)} M : </pre>
          </li>
          <li>
            <pre>{zeroPad(seconds, 2)} S</pre>
          </li>
        </ul>
      );
    }
  };

  const [caLoading, setCALoading] = useState(false);
  const [raLoading, setRALoading] = useState(false);
  const [startModalOpen, setStartModalOpen] = useState(false);
  const [allocationModalOpen, setAllocationModalOpen] = useState(false);
  const [vestingModalOpen, setVestingModalOpen] = useState(false);

  const handleAdminActions = async (key) => {
    if (key === "Start") {
      setStartModalOpen(true);
    } else if (key === "Cancel") {
      setCALoading(true);
      try {
        if (account) {
          let poolContract = getContract(poolAbi, stats.poolAddress, library);

          let tx = await poolContract.cancelAirdrop({
            from: account,
          });
          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 5000)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Waiting for confirmation",
          });

          let interval = setInterval(async function () {
            let web3 = getWeb3(chainId);
            var response = await web3.eth.getTransactionReceipt(tx.hash);
            if (response != null) {
              clearInterval(interval);
              if (response.status === true) {
                toast.success("success ! your last transaction is success");
                setUpdater(new Date());
                setCALoading(false);
              } else if (response.status === false) {
                toast.error("error ! Your last transaction is failed.");
                setUpdater(new Date());
                setCALoading(false);
              } else {
                toast.error("error ! something went wrong.");
                setUpdater(new Date());
                setCALoading(false);
              }
            }
          }, 5000);
        } else {
          toast.error("Please Connect to wallet !");
          setCALoading(false);
        }
      } catch (err) {
        toast.error(err.reason ? err.reason : err.message);
        setCALoading(false);
      }
    } else if (key === "SetAllocations") {
      setAllocationModalOpen(true);
    } else if (key === "SetVesting") {
      setVestingModalOpen(true);
    } else if (key === "RemoveAllAllocations") {
      setRALoading(true);
      try {
        if (account) {
          let poolContract = getContract(poolAbi, stats.poolAddress, library);

          let tx = await poolContract.removeAllocations({
            from: account,
          });
          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 5000)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Waiting for confirmation",
          });

          let interval = setInterval(async function () {
            let web3 = getWeb3(chainId);
            var response = await web3.eth.getTransactionReceipt(tx.hash);
            if (response != null) {
              clearInterval(interval);
              if (response.status === true) {
                toast.success("success ! your last transaction is success");
                setUpdater(new Date());
                setRALoading(false);
              } else if (response.status === false) {
                toast.error("error ! Your last transaction is failed.");
                setUpdater(new Date());
                setRALoading(false);
              } else {
                toast.error("error ! something went wrong.");
                setUpdater(new Date());
                setRALoading(false);
              }
            }
          }, 5000);
        } else {
          toast.error("Please Connect to wallet !");
          setRALoading(false);
        }
      } catch (err) {
        toast.error(err.reason ? err.reason : err.message);
        setRALoading(false);
      }
    }
  };

  const [allocations, setAllocations] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const hanldeSetAllocations = async () => {
    if (!allocations || allocations.length === 0) {
      toast.error("Please enter valid allocations info!");
      return;
    }
    const value = allocations.split("\n");
    const formatAllocations = value.map((item) => {
      let itemData = [];
      if (item.includes(",")) {
        itemData = item.split(",");
      } else {
        itemData = item.split(" ");
      }
      return { address: itemData[0], value: Number(itemData[1]) };
    });
    if (formatAllocations.length === 0) {
      toast.error("Please enter valid allocations info!");
      return;
    }

    setSubmitLoading(true);
    try {
      if (account) {
        let poolContract = getContract(poolAbi, stats.poolAddress, library);
        const adds = formatAllocations.map((item) => item.address);
        const amts = formatAllocations.map((item) =>
          parseUnits(item.value.toString(), stats.tokenDecimal)
        );
        let tx = await poolContract.setAllocations(adds, amts, {
          from: account,
        });
        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        let interval = setInterval(async function () {
          let web3 = getWeb3(chainId);
          var response = await web3.eth.getTransactionReceipt(tx.hash);
          if (response != null) {
            clearInterval(interval);
            if (response.status === true) {
              toast.success("success ! your last transaction is success");
              setUpdater(new Date());
              setSubmitLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              setSubmitLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              setSubmitLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        setSubmitLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setSubmitLoading(false);
    }
  };
  const handleApprove = async () => {
    setSubmitLoading(true);
    try {
      if (account) {
        let tokenContract = getContract(ERC20Abi, stats.token, library);

        let tx = await tokenContract.approve(
          stats.poolAddress,
          parseUnits(totalSendTokens.toString(), stats.tokenDecimal),
          {
            from: account,
          }
        );
        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        let interval = setInterval(async function () {
          let web3 = getWeb3(chainId);
          var response = await web3.eth.getTransactionReceipt(tx.hash);
          if (response != null) {
            clearInterval(interval);
            if (response.status === true) {
              toast.success("success ! your last transaction is success");
              setUpdater(new Date());
              setSubmitLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              setSubmitLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              setSubmitLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        setSubmitLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setSubmitLoading(false);
    }
  };

  const totalSendTokens = useMemo(() => {
    const value = allocations.split("\n");
    const formatAllocations = value.map((item) => {
      let itemData = [];
      if (item.includes(",")) {
        itemData = item.split(",");
      } else {
        itemData = item.split(" ");
      }
      return { address: itemData[0], value: Number(itemData[1]) };
    });
    return formatAllocations.reduce((sumValue, a) => sumValue + a.value, 0);
  }, [allocations]);

  const shouldApprove = useMemo(() => {
    return accStats.allowance < totalSendTokens;
  }, [accStats.allowance, totalSendTokens]);

  const claimedPercent = useMemo(() => {
    if (stats.totalAllocation === "0") return 0;
    return (
      (formatUnits(stats.totalClaimed.toString(), stats.tokenDecimal) /
        formatUnits(stats.totalAllocation.toString(), stats.tokenDecimal)) *
      100
    );
  }, [stats]);

  const myAllocation = useMemo(() => {
    if (!stats) return;
    if (!account) return;
    if (!stats.allocations || stats.allocations.length === 0) return;

    let ret = [];
    for (let i = 0; i < stats.allocations.length; i++) {
      const item = stats.allocations[i];
      if (item.recipient.toLowerCase() === account.toLowerCase()) {
        ret.push({ ...item, index: i });
      }
    }
    return ret;
  }, [stats, account]);

  const handleClaim = async (index) => {
    // setSubmitLoading(true);
    try {
      if (account) {
        let poolContract = getContract(poolAbi, stats.poolAddress, library);
        let tx = await poolContract.claim(index, {
          from: account,
        });
        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        let interval = setInterval(async function () {
          let web3 = getWeb3(chainId);
          var response = await web3.eth.getTransactionReceipt(tx.hash);
          if (response != null) {
            clearInterval(interval);
            if (response.status === true) {
              toast.success("success ! your last transaction is success");
              setUpdater(new Date());
              // setSubmitLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              // setSubmitLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              // setSubmitLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        // setSubmitLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      // setSubmitLoading(false);
    }
  };

  const [tge, setTge] = useState();
  const [cycle, setCycle] = useState();
  const [releasePercent, setReleasePercent] = useState();
  const handleSetVesting = async () => {
    if (!tge || !releasePercent || !releasePercent) {
      toast.error("Please put valid info!");
      return;
    }
    setSubmitLoading(true);
    try {
      if (account) {
        let poolContract = getContract(poolAbi, stats.poolAddress, library);
        let tx = await poolContract.setVesting(
          tge * 100,
          releasePercent * 100,
          cycle * 60,
          {
            from: account,
          }
        );
        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        let interval = setInterval(async function () {
          let web3 = getWeb3(chainId);
          var response = await web3.eth.getTransactionReceipt(tx.hash);
          if (response != null) {
            clearInterval(interval);
            if (response.status === true) {
              toast.success("success ! your last transaction is success");
              setVestingModalOpen(false);
              setUpdater(new Date());
              setSubmitLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              setSubmitLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              setSubmitLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        setSubmitLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setSubmitLoading(false);
    }
  };

  const [startTime, setStartTime] = useState(new Date());
  const handleSetStartTime = async () => {
    setSubmitLoading(true);
    try {
      if (account) {
        let poolContract = getContract(poolAbi, stats.poolAddress, library);
        let tx = await poolContract.setStartTime(new Date(startTime) / 1000, {
          from: account,
        });
        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 5000)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Waiting for confirmation",
        });

        let interval = setInterval(async function () {
          let web3 = getWeb3(chainId);
          var response = await web3.eth.getTransactionReceipt(tx.hash);
          if (response != null) {
            clearInterval(interval);
            if (response.status === true) {
              toast.success("success ! your last transaction is success");
              setVestingModalOpen(false);
              setUpdater(new Date());
              setSubmitLoading(false);
            } else if (response.status === false) {
              toast.error("error ! Your last transaction is failed.");
              setUpdater(new Date());
              setSubmitLoading(false);
            } else {
              toast.error("error ! something went wrong.");
              setUpdater(new Date());
              setSubmitLoading(false);
            }
          }
        }, 5000);
      } else {
        toast.error("Please Connect to wallet !");
        setSubmitLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setSubmitLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="detail-page mt-3">
        <section className="item-details-area">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="card project-card no-hover py-4 px-2">
                <div className="row">
                  {social.logourl && isValidLogoUrl && (
                    <div className="col-12 col-md-2 text-center">
                      <img
                        className="card-img-top avatar-max-lg mt-1 "
                        width="100%"
                        height="auto"
                        src={social.logourl}
                        onLoad={handleLoadLogo}
                        onError={handleErrorLogo}
                        alt="iconimage12"
                      />
                    </div>
                  )}
                  {!isValidLogoUrl && (
                    <div className="col-12 col-md-2 text-center">
                      <img
                        className="card-img-top avatar-max-lg mt-1 "
                        width="100%"
                        height="auto"
                        src={question}
                        alt="iconimage12"
                      />
                    </div>
                  )}
                  <div className="col-12 col-md-10">
                    <div className="row align-items-center justify-content-md-start justify-content-center">
                      <h4 className="mt-1 mb-2 text-center text-md-left">
                        {stats.tokenName} Airdrop
                      </h4>
                      <div className="d-flex gap-1 justify-content-center audit-status">
                        {stats.audit &&
                          (stats.auditStatus ||
                            (stats.auditLink && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={stats.auditLink}
                              >
                                <h4 className="tag-btn text-uppercase text-center bg-yellow">
                                  Audit
                                </h4>
                              </a>
                            )))}
                        {stats.kyc &&
                          (stats.kycStatus ||
                            (stats.kycLink && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={stats.kycLink}
                              >
                                <h4 className="tag-btn text-uppercase text-center bg-purple">
                                  KYC
                                </h4>
                              </a>
                            )))}
                      </div>
                    </div>
                    <div className="social-share d-flex justify-content-center justify-content-md-start">
                      <ul className="d-flex list-unstyled">
                        {social.twitter && (
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={social.twitter}
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                        )}
                        {social.telegram && (
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={social.telegram}
                            >
                              <i className="fab fa-telegram"></i>
                            </a>
                          </li>
                        )}
                        {social.website && (
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={social.website}
                            >
                              <i className="fas fa-globe"></i>
                            </a>
                          </li>
                        )}
                        {social.discord && (
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={social.discord}
                            >
                              <i className="fab fa-discord"></i>
                            </a>
                          </li>
                        )}
                        {social.facebook && (
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={social.facebook}
                            >
                              <i className="fab fa-facebook"></i>
                            </a>
                          </li>
                        )}
                        {social.github && (
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={social.github}
                            >
                              <i className="fab fa-github"></i>
                            </a>
                          </li>
                        )}

                        {social.instagram && (
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={social.instagram}
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        )}

                        {social.reddit && (
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={social.reddit}
                            >
                              <i className="fab fa-reddit"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <p className="text-center text-md-left">
                      {editSocial.brief}
                    </p>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Airdrop Address</p>
                    <p>
                      {formatAddress(stats.poolAddress)}
                      <CopyToClipboard
                        text={stats.poolAddress}
                        onCopy={() => {
                          setRefcopy1(true);
                          setTimeout(() => {
                            setRefcopy1(false);
                          }, 2000);
                        }}
                      >
                        <img
                          className="ml-2"
                          src={require("../../../images/icon.png").default}
                          alt="project"
                        />
                      </CopyToClipboard>
                      <span>{refcopy1 && "copied"}</span>
                    </p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Token Name</p>
                    <p>{stats.tokenName}</p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Token Symbol</p>
                    <p>{stats.tokenSymbol}</p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Token Decimals</p>
                    <p>{stats.tokenDecimal}</p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Token Address</p>
                    <p>
                      {formatAddress(stats.token)}
                      <CopyToClipboard
                        text={stats.token}
                        onCopy={() => {
                          setRefcopy2(true);
                          setTimeout(() => {
                            setRefcopy2(false);
                          }, 2000);
                        }}
                      >
                        <img
                          className="ml-2"
                          src={require("../../../images/icon.png").default}
                          alt="project"
                        />
                      </CopyToClipboard>
                      <span>{refcopy2 && "copied"}</span>
                    </p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Total Supply</p>
                    <p>
                      {formatPrice(stats.tokenSupply)} {stats.tokenSymbol}
                    </p>
                  </div>
                  <div className="col-12 my-2 d-flex justify-content-between">
                    <p>Airdrop Start Time </p>
                    <p>
                      {stats.startTime === "0"
                        ? "--"
                        : dateFormat(
                            new Date(stats.startTime * 1000),
                            "yyyy-mm-dd HH:MM"
                          )}
                    </p>
                  </div>
                </div>
                <div className="presale-status">
                  {stats.startTime === "0" ? (
                    <h4 className="tag-btn text-uppercase text-center bg-upcoming">
                      Upcoming
                    </h4>
                  ) : parseInt(stats.startTime) >
                    Math.floor(new Date().getTime() / 1000.0) ? (
                    <h4 className="tag-btn text-uppercase text-center bg-upcoming">
                      Upcoming
                    </h4>
                  ) : parseInt(stats.startTime) <
                    Math.floor(new Date().getTime() / 1000.0) ? (
                    <h4 className="tag-btn text-uppercase text-center bg-active">
                      Active
                    </h4>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card project-card no-hover">
                <div className="card no-hover staking-card single-staking">
                  <div className="card-body">
                    <div className="mt-md-0 mt-3 d-flex justify-content-center">
                      <div className="countdown">
                        <div
                          className="text-center"
                          style={{ fontSize: "1.1rem" }}
                        >
                          {parseInt(stats.startTime) === 0
                            ? "TBD"
                            : parseInt(stats.startTime) >
                              Math.floor(new Date().getTime() / 1000.0)
                            ? "Start In"
                            : ""}
                        </div>
                        <Countdown
                          key={Math.floor(Math.random() * 10 + 1)}
                          date={stats.startTime * 1000}
                          renderer={countdownrender}
                        />
                      </div>
                    </div>
                    <div className="item-progress">
                      <div className="progress mb-1">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${claimedPercent}%`,
                          }}
                          aria-valuenow={parseFloat(claimedPercent).toFixed(2)}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {claimedPercent}%
                        </div>
                      </div>
                      <div className="progress-sale d-flex justify-content-between">
                        <span>0 {stats.currencySymbol}</span>
                        <span>
                          {formatUnits(
                            stats.totalAllocation.toString(),
                            stats.tokenDecimal
                          )}{" "}
                          {stats.tokenSymbol}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between mb-2">
                      <p>Your Allocations</p>
                      <p>
                        {myAllocation
                          ? myAllocation.reduce(
                              (sumValue, a) =>
                                sumValue +
                                parseFloat(
                                  formatUnits(
                                    a.amount.toString(),
                                    stats.tokenDecimal
                                  )
                                ),
                              0
                            )
                          : 0}{" "}
                        {stats.tokenSymbol}
                      </p>
                    </div>
                    {myAllocation && myAllocation.length > 0 && (
                      <div style={{ width: "100%" }}>
                        <div className="claim-grid">
                          <div>#</div>
                          <div>Allocated</div>
                          <div>Claimed</div>
                          <div></div>
                        </div>
                        {myAllocation &&
                          myAllocation.map((item, index) => {
                            return (
                              <div className="claim-grid" key={index}>
                                <div>{index + 1}</div>
                                <div>
                                  {formatUnits(
                                    item.amount.toString(),
                                    stats.tokenDecimal
                                  )}
                                </div>
                                <div>
                                  {formatUnits(
                                    item.claimed.toString(),
                                    stats.tokenDecimal
                                  )}
                                </div>
                                <button
                                  onClick={() => handleClaim(item.index)}
                                  style={{ width: "80px", borderRadius: "8px" }}
                                >
                                  Claim
                                </button>
                              </div>
                            );
                          })}
                      </div>
                    )}
                    <div className="d-flex justify-content-between mb-2">
                      <p>Your Claimed</p>
                      <p>
                        {myAllocation
                          ? myAllocation.reduce(
                              (sumValue, a) =>
                                sumValue +
                                parseFloat(
                                  formatUnits(
                                    a.claimed.toString(),
                                    stats.tokenDecimal
                                  )
                                ),
                              0
                            )
                          : 0}{" "}
                        {stats.tokenSymbol}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {account &&
                account.toLowerCase() === stats.owner.toLowerCase() && (
                  <div className="card project-card no-hover">
                    <div className="card no-hover staking-card single-staking">
                      <div className="card-body">
                        <div className="mt-md-0 mt-3 d-flex justify-content-center">
                          <div className="countdown">
                            <div
                              className="text-center"
                              style={{ fontSize: "1.1rem" }}
                            >
                              Admin Zone
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="warning-area">
                          Please don't start the airdrop before you finalize the
                          airdrop pool.
                        </div>
                        <div className="warning-area">
                          You must exclude fees, dividens, max tx for airdrop
                          address to start the airdrop.
                        </div>
                        <Button onClick={() => handleAdminActions("Start")}>
                          Start Airdrop
                        </Button>
                        <Button onClick={() => handleAdminActions("Cancel")}>
                          Cancel Airdrop
                        </Button>
                        <div>Allocation Actions</div>
                        <Button
                          onClick={() => handleAdminActions("SetAllocations")}
                        >
                          Set Allocations
                        </Button>
                        <Button
                          onClick={() => handleAdminActions("SetVesting")}
                        >
                          Set Vesting
                        </Button>
                        <Button
                          onClick={() =>
                            handleAdminActions("RemoveAllAllocations")
                          }
                        >
                          Remove All Allocations
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={allocationModalOpen}
        onHide={() => setAllocationModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Allocations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                onChange={(e) => {
                  setAllocations(e.target.value);
                }}
                rows={8}
                placeholder={
                  "Insert allocation: separate with breaks line. By format: address, amount or address amount.\nEx.\n0x0000000000000000000000000000000000001000 10\n0x0000000000000000000000000000000000001000 13.5"
                }
                value={allocations}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button
                variant="none"
                className="btn btn-success"
                loading={submitLoading}
                onClick={(e) => {
                  shouldApprove ? handleApprove() : hanldeSetAllocations();
                }}
              >
                {shouldApprove ? "Approve" : "Submit"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={vestingModalOpen}
        onHide={() => setVestingModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Vesting</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-0">
                <div className="form-group">
                  <label>
                    TGE release percent (%)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => setTge(e.target.value)}
                    type="text"
                    value={tge}
                    placeholder="50%"
                  />
                  {/* <small className="text-danger">{error.bannerurl}</small> */}
                  <br />
                </div>
              </div>
              <div className="col-md-6 mb-0">
                <div className="form-group">
                  <label>
                    Cycle release percent (%)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => setReleasePercent(e.target.value)}
                    type="text"
                    value={releasePercent}
                    placeholder="10%"
                  />
                  {/* <small className="text-danger">{error.bannerurl}</small> */}
                  <br />
                </div>
              </div>
              <div className="col-md-6 mb-0">
                <div className="form-group">
                  <label>
                    Cycle (minutes)<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => setCycle(e.target.value)}
                    type="text"
                    value={cycle}
                    placeholder="0"
                  />
                  {/* <small className="text-danger">{error.bannerurl}</small> */}
                  <br />
                </div>
              </div>
              <div className="col-md-12 mt-4 mb-0 d-flex justify-content-center">
                <Button
                  className="btn btn-success"
                  loading={submitLoading}
                  onClick={(e) => handleSetVesting(e)}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={startModalOpen}
        onHide={() => setStartModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Setting time to start</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-4 mb-0">
                <div className="form-group">
                  <label>
                    Start time (LocalTime)<span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    isClearable
                    placeholderText="Select Start Time!"
                    minDate={new Date()}
                    showDisabledMonthNavigation
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
                <small className="text-warn">
                  Set the time that you want to start this airdrop
                </small>
                <br />
              </div>
              <div className="col-md-12 mt-4 mb-0 d-flex justify-content-center">
                <Button
                  className="btn btn-success"
                  loading={submitLoading}
                  onClick={(e) => handleSetStartTime(e)}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
