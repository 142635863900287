export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

//Launchpad Contract

export const contract = {
  // 97: {
  //   // bsc testnet
  //   poolfactory: "0xd80aB0dB4969893eb533AB5ed62D47F5Bb39fcb4",
  //   routeraddress: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
  //   multicallAddress: "0x8fD9e79fc2542aa3Caa845E23ef7F36bbc9bf274",
  //   lockAddress: "0x4409b240390804A1971F0eb820a88e912e26f0d3",
  //   routername: "PancakeSwap",
  //   feeReceiver: "0xC7bFA6dE3ecfd0E05002a29DbE0c57944b19338B",
  //   dividendTracker: "0x03557C7f68Cf5FB8F54778265790BEb19475C0f1",
  //   multiSender: "0xD77e195a9a0CA0aC70dc61Bf3Da0AA5f1D0b5338",
  //   airdropFactory: "0x788a4EC141eb8a5d89aEe61b8788e84314471904",
  // },
  56: {
    // bsc mainnet
    poolfactory: "0x81133f7b79b0036a88dc43a1385e8869b9426e58",
    routeraddress: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    multicallAddress: "0x3161eaf6ecc0c53abe0e5ea762e0c73b43a2453e",
    lockAddress: "0x5ba34d549D8232dbcb0d48ed8958C2d8CC0C20da",
    routername: "Pancakeswap",
    feeReceiver: "0xC7bFA6dE3ecfd0E05002a29DbE0c57944b19338B",
    dividendTracker: "0x000000000000000000000000000000000000dead",
    multiSender: "0x676bb10f3241F87a8414e11F45dC55c41F839309",
    airdropFactory: "0x37FF821ccbEc8b1a2d91aAeb6373100eec1886Ec",
  },
  137: {
    // polygon mainnet
    poolfactory: "0x81133f7b79b0036a88dc43a1385e8869b9426e58",
    routeraddress: "0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff",
    multicallAddress: "0x3161eaF6ecc0C53Abe0e5ea762E0c73b43a2453E",
    lockAddress: "0x61201f5e9a132697146e5C81b579843E5f7eB08c",
    routername: "QuickSwap",
    feeReceiver: "0xC7bFA6dE3ecfd0E05002a29DbE0c57944b19338B",
    dividendTracker: "0x000000000000000000000000000000000000dead",
    multiSender: "0x8f08fDc1Af06EC338ab337fF4B417C96b769DE2F",
    airdropFactory: "0xB66aF536B53278b0933163f29ab9c43be5Da4c3C",
  },
  43114: {
    // avalanche mainnet
    poolfactory: "0xAba3f5Ac2ED064052733B4832Ad495E99079c6dC",
    routeraddress: "0x60aE616a2155Ee3d9A68541Ba4544862310933d4",
    multicallAddress: "0x3161eaF6ecc0C53Abe0e5ea762E0c73b43a2453E",
    lockAddress: "0x61201f5e9a132697146e5C81b579843E5f7eB08c",
    routername: "TraderJoe",
    feeReceiver: "0xC7bFA6dE3ecfd0E05002a29DbE0c57944b19338B",
    dividendTracker: "0x000000000000000000000000000000000000dead",
    multiSender: "0x676bb10f3241F87a8414e11F45dC55c41F839309",
    airdropFactory: "0xA89710954F18F5398CccDcdd0D3a4452Ac9380F0",
  },
  default: {
    poolfactory: "0x81133f7b79b0036a88dc43a1385e8869b9426e58",
    routeraddress: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    multicallAddress: "0x3161eaf6ecc0c53abe0e5ea762e0c73b43a2453e",
    lockAddress: "0x5ba34d549D8232dbcb0d48ed8958C2d8CC0C20da",
    routername: "Pancakeswap",
    feeReceiver: "0xC7bFA6dE3ecfd0E05002a29DbE0c57944b19338B",
    dividendTracker: "0x000000000000000000000000000000000000dead",
    multiSender: "0x676bb10f3241F87a8414e11F45dC55c41F839309",
    airdropFactory: "0x37FF821ccbEc8b1a2d91aAeb6373100eec1886Ec",
  },
};
