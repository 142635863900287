import React, { useMemo, useState } from "react";
import CircleLoader from "react-spinners/CircleLoader";
import { useAirdropList } from "./helper/useStats";
import AirdropCard from "../../../component/AirdropCard";
import { useWeb3React } from "@web3-react/core";

export default function AirdropList() {
  const { account } = useWeb3React();
  const [type, setType] = useState("ALL");
  const [updater, setUpdater] = useState({
    page: 0,
    pageSize: 30,
    loading: true,
  });
  const statsT = useAirdropList(updater, type);
  const stats = useMemo(() => {
    if (type === "ALL") return statsT;
    if (type === "MINE") {
      const ret = statsT;
      if (!account) {
        ret.airdropList = [];
      } else {
        if (!statsT.airdropList || statsT.airdropList.length === 0) {
          ret.airdropList = [];
        } else {
          ret.airdropList = statsT.airdropList.filter(
            (item) => item.owner.toLowerCase() === account.toLowerCase()
          );
        }
      }
      console.log("sniper: stats:", ret);
      return ret;
    }
  }, [statsT, account, type]);

  return (
    <React.Fragment>
      <section className="project-area explore-area">
        <div className="intro">
          <div className="intro-content text-center">
            <span className="intro-text">Airdrop List</span>
          </div>
          <div
            className="explore-menu btn-group btn-group-toggle flex-wrap mt-5 pl-3"
            data-toggle="buttons"
          >
            <label
              className={`btn text-uppercase p-2 ${
                type === "ALL" ? "press" : ""
              }`}
              onClick={() => setType("ALL")}
            >
              All
            </label>
            <label
              className={`btn text-uppercase p-2 ${
                type === "MINE" ? "press" : ""
              }`}
              onClick={() => setType("MINE")}
            >
              My Airdrop
            </label>
          </div>
        </div>

        <div className="row items">
          <React.Fragment>
            {!stats || stats.loading ? (
              <div className="col-md-12">
                <CircleLoader
                  size="50"
                  color="#757575"
                  cssOverride={{
                    position: "fixed",
                    left: "50%",
                    textAlign: "center",
                    top: "50%",
                  }}
                />
              </div>
            ) : stats.airdropList.length > 0 ? (
              stats.airdropList
                .slice(0)
                .reverse()
                .map((rowdata, index) => {
                  return (
                    <AirdropCard
                      chainId={stats.chainId}
                      rowdata={rowdata}
                      index={index}
                      key={index}
                    />
                  );
                })
            ) : (
              <div className="col-12 item explore-item mt-5">
                <p className="text-center">No Record Found</p>
              </div>
            )}
          </React.Fragment>
        </div>
        {stats && (
          <div className="row mt-4 mt-md-5">
            <div className="col-12">
              <nav>
                <ul className="page-numbers">
                  {stats.page > 0 && (
                    <li>
                      <a
                        className="next page-numbers"
                        href="#sec"
                        onClick={(e) =>
                          setUpdater({
                            page: stats.page - 1,
                            pageSize: stats.pageSize,
                            loading: true,
                          })
                        }
                      >
                        <i className="icon-arrow-left"></i>Previous
                      </a>
                    </li>
                  )}
                  {Math.floor(stats.getTotalNumberOfPools / stats.pageSize) >
                    stats.page && (
                    <li>
                      <a
                        className="next page-numbers"
                        href="#sec"
                        onClick={(e) =>
                          setUpdater({
                            page: stats.page + 1,
                            pageSize: stats.pageSize,
                            loading: true,
                          })
                        }
                      >
                        Next
                        <i className="icon-arrow-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
}
