import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  FaRocket,
  FaShieldAlt,
  FaLock,
  FaDochub,
  FaTelegramPlane,
  FaTwitter,
  FaHome,
  FaDropbox,
  FaPlusCircle,
  FaPiggyBank,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import styled from "styled-components";
import queryString from "query-string";

const MenuIcon = styled.button`
  display: none;
  position: absolute;
  left: 65px;
  top: 10px;
  padding: 10px 15px;
  z-index: 1000;
  border: none !important;

  @media screen and (max-width: 425px) {
    display: block;
  }
`;

const CloseIcon = styled.button`
  position: absolute;
  right: 5px;
  top: 14px;
  padding: 10px 15px;
  z-index: 10000;
  border: none !important;
`;

const Menuitem = styled(MenuItem)`
  :hover {
    background-color: #ffdb58;
    padding: 5px;
    border-radius: 10px;
  }
`;

export function useRouter() {
  const params = useParams();
  const location = useLocation();

  return useMemo(() => {
    return {
      push: location.push,
      replace: location.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      location,
    };
  }, [params, location]);
}

const MainLayout = (props) => {
  const router = useRouter();

  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 575;

  const [collapsed, setCollapsed] = useState(true);
  const onClickMenuIcon = (status) => {
    console.log(status);
    setCollapsed(status);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const onClickMenuItem = () => {
    if (isMobile) setCollapsed(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <React.Fragment>
      <MenuIcon className="btn" onClick={() => onClickMenuIcon(false)}>
        <FaBars />
      </MenuIcon>
      {collapsed === false && (
        <CloseIcon className="btn" onClick={() => onClickMenuIcon(true)}>
          <FaTimes />
        </CloseIcon>
      )}
      <ProSidebar
        collapsed={collapsed}
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
      >
        <SidebarHeader>
          <img
            src={require("../images/logo.png").default}
            alt="Brand Logo"
            width="40px"
          />
          <h3>
            Slap <span> A </span> Meme
          </h3>
        </SidebarHeader>
        <Menu iconShape="square">
          <Menuitem
            icon={<FaHome />}
            className={`${router.pathname === "/" ? "active" : ""}`}
          >
            <Link to="/" onClick={onClickMenuItem}>
              Home
            </Link>
          </Menuitem>
          <Menuitem
            icon={<FaPlusCircle />}
            className={`${router.pathname === "/token" ? "active" : ""}`}
          >
            <Link to="/token" onClick={onClickMenuItem}>
              Token Creator
            </Link>
          </Menuitem>
          <SubMenu
            title="Launchpads"
            icon={<FaRocket />}
            className={`${
              router.pathname === "/presale" ||
              router.pathname === "/fairlaunch" ||
              router.pathname === "/sale-list" ||
              router.pathname === "/my-contribution"
                ? "active"
                : ""
            }`}
          >
            <Menuitem
              className={`${router.pathname === "/presale" ? "active" : ""}`}
            >
              <Link to="/presale" onClick={onClickMenuItem}>
                Create launchpad
              </Link>
            </Menuitem>
            <MenuItem
              className={`${router.pathname === "/fairlaunch" ? "active" : ""}`}
            >
              <Link to="/fairlaunch" onClick={onClickMenuItem}>
                Create fair launch
              </Link>
            </MenuItem>
            <MenuItem
              className={`${
                router.pathname === "/sale-list" ||
                router.pathname === "/my-contribution"
                  ? "active"
                  : ""
              }`}
            >
              <Link to="/sale-list" onClick={onClickMenuItem}>
                Launchpad list
              </Link>
            </MenuItem>
          </SubMenu>
          <SubMenu
            title="Private Sale"
            icon={<FaShieldAlt />}
            className={`${
              router.pathname === "/privatesale" ||
              router.pathname === "/prvsale-list" ||
              router.pathname === "/prv-contribution"
                ? "active"
                : ""
            }`}
          >
            <Menuitem
              className={`${
                router.pathname === "/privatesale" ? "active" : ""
              }`}
            >
              <Link to="/privatesale" onClick={onClickMenuItem}>
                Create Private Sale
              </Link>
            </Menuitem>
            <MenuItem
              className={`${
                router.pathname === "/prvsale-list" ||
                router.pathname === "/prv-contribution"
                  ? "active"
                  : ""
              }`}
            >
              <Link to="/prvsale-list" onClick={onClickMenuItem}>
                Private Sale list
              </Link>
            </MenuItem>
          </SubMenu>
          <SubMenu
            title="SlapPad Lock"
            icon={<FaLock />}
            className={`${
              router.pathname === "/lock" ||
              router.pathname === "/token-locked" ||
              router.pathname === "/liquidity-locked"
                ? "active"
                : ""
            }`}
          >
            <Menuitem
              className={`${router.pathname === "/lock" ? "active" : ""}`}
            >
              <Link to="/lock" onClick={onClickMenuItem}>
                Create Lock
              </Link>
            </Menuitem>
            <MenuItem
              className={`${
                router.pathname === "/token-locked" ? "active" : ""
              }`}
            >
              <Link to="/token-locked" onClick={onClickMenuItem}>
                Token
              </Link>
            </MenuItem>
            <MenuItem
              className={`${
                router.pathname === "/liquidity-locked" ? "active" : ""
              }`}
            >
              <Link to="/liquidity-locked" onClick={onClickMenuItem}>
                Liquidity
              </Link>
            </MenuItem>
          </SubMenu>
          <SubMenu
            title="Airdrop"
            icon={<FaDropbox />}
            className={`${
              router.pathname === "/airdrop" ||
              router.pathname === "/airdrop-list"
                ? "active"
                : ""
            }`}
          >
            <Menuitem
              className={`${router.pathname === "/airdrop" ? "active" : ""}`}
            >
              <Link to="/airdrop" onClick={onClickMenuItem}>
                Create Airdrop
              </Link>
            </Menuitem>
            <MenuItem
              className={`${
                router.pathname === "/airdrop-list" ? "active" : ""
              }`}
            >
              <Link to="/airdrop-list" onClick={onClickMenuItem}>
                Airdrop list
              </Link>
            </MenuItem>
          </SubMenu>
          <Menuitem
            icon={<FaPiggyBank />}
            className={`${router.pathname === "/multisender" ? "active" : ""}`}
          >
            <Link to="/multisender" onClick={onClickMenuItem}>
              Multi Sender
            </Link>
          </Menuitem>
          {/* <SubMenu
            title="Staking"
            icon={<FaPiggyBank />}
            className={`${
              router.pathname === "/staking" ||
              router.pathname === "/staking-list"
                ? "active"
                : ""
            }`}
          >
            <Menuitem
              className={`${router.pathname === "/staking" ? "active" : ""}`}
            >
              <Link to="/staking" onClick={onClickMenuItem}>
                Create Staking
              </Link>
            </Menuitem>
            <MenuItem
              className={`${
                router.pathname === "/staking-list" ? "active" : ""
              }`}
            >
              <Link to="/staking-list" onClick={onClickMenuItem}>
                Staking list
              </Link>
            </MenuItem>
          </SubMenu> */}
          {/* <Menuitem icon={<SiAdobeaudition />}>
            <a href="https://t.me/king_arthuree" target="_blank">
              KYC & Audit
            </a>
          </Menuitem> */}
          <Menuitem icon={<FaDochub />}>
            <a
              href="https://wonders-personal-organization.gitbook.io/slappad"
              target="_blank"
              rel="noreferrer"
            >
              Doc
            </a>
          </Menuitem>
          <Menuitem icon={<FaTelegramPlane />}>
            <a href="https://t.me/slapameme" target="_blank" rel="noreferrer">
              Telegram
            </a>
          </Menuitem>
          <Menuitem icon={<FaTwitter />}>
            <a
              href="https://twitter.com/slapameme"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </Menuitem>
        </Menu>
      </ProSidebar>
    </React.Fragment>
  );
};
export default MainLayout;
