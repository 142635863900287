export const currencies = {
  97: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
    { address: "0x7ef95a0fee0dd31b22626fa2e10ee6a223f8a684", symbol: "USDT" },
  ],
  56: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
    { address: "0x55d398326f99059fF775485246999027B3197955", symbol: "USDT" },
    { address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d", symbol: "USDC" },
  ],
  137: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "MATIC" },
    { address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F", symbol: "USDT" },
    { address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359", symbol: "USDC" },
  ],
  43114: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "AVAX" },
    { address: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7", symbol: "USDT" },
    { address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E", symbol: "USDC" },
  ],
  default: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
    { address: "0x55d398326f99059fF775485246999027B3197955", symbol: "USDT" },
    { address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d", symbol: "USDC" },
  ],
};
