import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { formatPrice } from "../hooks/contractHelper";
import { AiFillTwitterCircle } from "react-icons/ai";
import {
  RiEarthFill,
  RiTelegramFill,
  RiDiscordFill,
  RiFacebookCircleFill,
  RiGithubFill,
  RiInstagramFill,
  RiRedditFill,
} from "react-icons/ri";
import question from "../images/question.png";
import { useTokenInfo } from "../pages/launchpadApply/SaleList/helper/useStats";
import { ethers } from "ethers";

const ProjectCard = ({ chainId, rowdata, index, cs }) => {
  const [status, setStatus] = useState("");
  const [social, setSocial] = useState([]);
  // const [startTime, setStartTime] = useState(0);
  // const [endTime, setEndTime] = useState(0);

  const [isValidUrl, setIsValidUrl] = useState(true);
  const handleLoad = useCallback(() => {
    setIsValidUrl(true);
  }, []);
  const handleError = useCallback(() => {
    setIsValidUrl(false);
  }, []);

  const tokenInfo = useTokenInfo(rowdata.token, chainId);
  useEffect(() => {
    if (rowdata.poolState !== undefined) {
      if (rowdata.poolState === "1") {
        setStatus("completed");
      } else if (rowdata.poolState === "2") {
        setStatus("canceled");
      } else if (
        parseInt(rowdata.endTime) < Math.floor(new Date().getTime() / 1000.0)
      ) {
        setStatus("ended");
      } else if (
        parseInt(rowdata.startTime) > Math.floor(new Date().getTime() / 1000.0)
      ) {
        setStatus("upcoming");
      } else if (
        parseInt(rowdata.startTime) <
          Math.floor(new Date().getTime() / 1000.0) &&
        parseInt(rowdata.endTime) > Math.floor(new Date().getTime() / 1000.0)
      ) {
        setStatus("active");
      }
    }
    if (cs !== undefined) setStatus(cs);

    if (rowdata.poolDetails !== undefined) {
      let details = rowdata.poolDetails.toString().split("$#$");
      const _social = {
        logourl: details[0],
        bannerurl: details[1],
        website: details[2],
        blockstar: details[3],
        facebook: details[4],
        twitter: details[5],
        github: details[6],
        telegram: details[7],
        instagram: details[8],
        discord: details[9],
        reddit: details[10],
        youtube: details[11],
        brief: details[12],
      };
      setSocial(_social);
    }

    // if (rowdata.startTime !== undefined && rowdata.endTime !== undefined) {
    //   const _startTime = new Date(rowdata.startTime * 1000);
    //   const _endTime = new Date(rowdata.endTime * 1000);
    //   setStartTime(_startTime);
    //   setEndTime(_endTime);
    // }
  }, [
    cs,
    rowdata.endTime,
    rowdata.poolDetails,
    rowdata.poolState,
    rowdata.startTime,
    status,
  ]);

  return (
    <div
      className="col-12 col-md-6 col-lg-4 item explore-item mb-4"
      key={index}
    >
      <div className="card project-card">
        <div
          className="card-header py-4 px-2"
          style={{
            background: `url(${social.bannerurl}), linear-gradient(45deg, #10161d, transparent)`,
          }}
        >
          <div className="d-flex">
            <div className="project-logo ml-2">
              {isValidUrl && (
                <img
                  src={social.logourl}
                  width="50px"
                  alt="logo"
                  onLoad={handleLoad}
                  onError={handleError}
                />
              )}
              {!isValidUrl && <img src={question} width="50px" alt="logo" />}
            </div>
            {/* <div>
              <div>
                <Link
                  to={
                    rowdata.poolType === "0"
                      ? `/presale-details/${rowdata.poolAddress}${
                          chainId ? `?chainid=${chainId}` : ""
                        }`
                      : rowdata.poolType === "1"
                      ? `/private-details/${rowdata.poolAddress}${
                          chainId ? `?chainid=${chainId}` : ""
                        }`
                      : rowdata.poolType === "2"
                      ? `/fairlaunch-details/${rowdata.poolAddress}??chainid=${chainId}`
                      : "/"
                  }
                >
                  <h5 className="mx-0 mt-2 mb-0">
                    {tokenInfo.name ? tokenInfo.name : ""}
                  </h5>
                </Link>
              </div>
              <div className="social-icons">
                {social.website && (
                  <a target="_blank" rel="noreferrer" href={social.website}>
                    <RiEarthFill />
                  </a>
                )}
                {social.twitter && (
                  <a target="_blank" rel="noreferrer" href={social.twitter}>
                    <AiFillTwitterCircle />
                  </a>
                )}
                {social.facebook && (
                  <a target="_blank" rel="noreferrer" href={social.facebook}>
                    <RiFacebookCircleFill />
                  </a>
                )}
                {social.instagram && (
                  <a target="_blank" rel="noreferrer" href={social.instagram}>
                    <RiInstagramFill />
                  </a>
                )}
                {social.reddit && (
                  <a target="_blank" rel="noreferrer" href={social.reddit}>
                    <RiRedditFill />
                  </a>
                )}
                {social.telegram && (
                  <a target="_blank" rel="noreferrer" href={social.telegram}>
                    <RiTelegramFill />
                  </a>
                )}
                {social.discord && (
                  <a target="_blank" rel="noreferrer" href={social.discord}>
                    <RiDiscordFill />
                  </a>
                )}
                {social.github && (
                  <a target="_blank" rel="noreferrer" href={social.github}>
                    <RiGithubFill />
                  </a>
                )}
              </div>
            </div> */}
          </div>
          <div className="d-flex flex-column align-items-end gap-4">
            <span>
              {rowdata.poolType === "0" ? (
                <h4 className="tag-btn text-uppercase text-center bg-blue">
                  Presale
                </h4>
              ) : rowdata.poolType === "1" ? (
                <h4 className="tag-btn text-uppercase text-center bg-green">
                  PrivateSale
                </h4>
              ) : rowdata.poolType === "2" ? (
                <h4 className="tag-btn text-uppercase text-center bg-pink">
                  Fair Launch
                </h4>
              ) : (
                ""
              )}
            </span>
            <span>
              <h4
                className={
                  "tag-btn tag-left-circle text-uppercase text-center bg-" +
                  status
                }
              >
                {status}
              </h4>
            </span>
          </div>
        </div>
        <div className="card-body">
          <div className="fund-detail">
            <div className="d-flex justify-content-between align-items-start">
              <h4 className="mb-0">{tokenInfo.name ? tokenInfo.name : ""}</h4>
              <div className="social-icons">
                {social.website && (
                  <a target="_blank" rel="noreferrer" href={social.website}>
                    <RiEarthFill />
                  </a>
                )}
                {social.twitter && (
                  <a target="_blank" rel="noreferrer" href={social.twitter}>
                    <AiFillTwitterCircle />
                  </a>
                )}
                {social.facebook && (
                  <a target="_blank" rel="noreferrer" href={social.facebook}>
                    <RiFacebookCircleFill />
                  </a>
                )}
                {social.instagram && (
                  <a target="_blank" rel="noreferrer" href={social.instagram}>
                    <RiInstagramFill />
                  </a>
                )}
                {social.reddit && (
                  <a target="_blank" rel="noreferrer" href={social.reddit}>
                    <RiRedditFill />
                  </a>
                )}
                {social.telegram && (
                  <a target="_blank" rel="noreferrer" href={social.telegram}>
                    <RiTelegramFill />
                  </a>
                )}
                {social.discord && (
                  <a target="_blank" rel="noreferrer" href={social.discord}>
                    <RiDiscordFill />
                  </a>
                )}
                {social.github && (
                  <a target="_blank" rel="noreferrer" href={social.github}>
                    <RiGithubFill />
                  </a>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <p>Soft{rowdata.poolType !== "2" && "/Hard"} Cap:</p>
              <p className="ml-1">
                {rowdata.softCap}
                {rowdata.poolType !== "2" && "/" + rowdata.hardCap}{" "}
                {rowdata.currencySymbol}
              </p>
            </div>
          </div>
          {/* <div className="date-detail mt-3">
            <div className="d-flex justify-content-between">
              <span>Start Time:</span>
              <span className="ml-1">
                {dateFormat(startTime, "yyyy-mm-dd HH:MM")}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span>End Time:</span>
              <span className="ml-1">
                {dateFormat(endTime, "yyyy-mm-dd HH:MM")}
              </span>
            </div>
          </div> */}
          <div className="item-progress my-2">
            <div>
              <span>
                Progress : {parseFloat(rowdata.percentageRaise).toFixed(2)}%
              </span>
            </div>
            <div className="progress mt-md-1 ">
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${parseFloat(rowdata.percentageRaise).toFixed(2)}%`,
                }}
                aria-valuenow={parseFloat(rowdata.percentageRaise).toFixed(2)}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="d-flex justify-content-between mt-1">
              <span>
                {rowdata.totalRaised ? rowdata.totalRaised : 0}{" "}
                {rowdata.currencySymbol}
              </span>
              <span>
                {rowdata.poolType !== "2" ? rowdata.hardCap : rowdata.softCap}{" "}
                {rowdata.currencySymbol}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <span>
                {rowdata.poolType !== "2" && (
                  <>
                    1 {rowdata.currencySymbol} ={" "}
                    {formatPrice(
                      ethers.utils.formatUnits(rowdata.rate, tokenInfo.decimals)
                    )}{" "}
                    {tokenInfo.symbol}
                  </>
                )}
              </span>
            </div>
            <Link
              to={
                rowdata.poolType === "0"
                  ? `/presale-details/${rowdata.poolAddress}${
                      chainId ? `?chainid=${chainId}` : ""
                    }`
                  : rowdata.poolType === "1"
                  ? `/private-details/${rowdata.poolAddress}${
                      chainId ? `?chainid=${chainId}` : ""
                    }`
                  : rowdata.poolType === "2"
                  ? `/fairlaunch-details/${rowdata.poolAddress}??chainid=${chainId}`
                  : "/"
              }
              className="btn btn-smaller"
            >
              View Pool
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
