import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getChainInfo, getSubgraphUrl } from "../../../../hooks/network";
import { getApolloClient } from "../../../../utils/apolloHelpers";
import { gql } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";
import { formatUnits } from "ethers/lib/utils";

export const GETAIRDROPS_QUERY = gql`
  query getAirsrops {
    airdrops(first: $first, skip: $skip) {
      totalClaimedAmount
      totalAllocationNumber
      totalAllocationAmount
      token
      startTime
      poolDetails
      poolAddress
      participants
      id
      createdBy
      createdAt
    }
  }
`;

export const useAirdropList = (updater, type) => {
  let { page, pageSize, loading } = updater;
  const context = useWeb3React();
  const { chainId } = context;
  let history = useHistory();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainid");

  const { _chainId_ } = getChainInfo(queryChainId, chainId);

  const [stats, setStats] = useState({
    getTotalNumberOfPools: 0,
    page: page,
    pageSize: pageSize,
    airdropList: [],
    loading: true,
    chainId: _chainId_,
  });

  const apolloClient = getApolloClient(getSubgraphUrl(_chainId_));

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await apolloClient.query({
          query: GETAIRDROPS_QUERY,
          variables: {
            first: pageSize,
            skip: page * pageSize,
          },
        });
        let _airdrops = cloneDeep(response.data["airdrops"]);

        if (_airdrops.length > 0) {
          Promise.all(
            _airdrops.map(async (value) => {
              const _startTime = parseFloat(formatUnits(value.startTime, 0));
              const _now = new Date() / 1000;
              const _poolState =
                _startTime === 0 || _startTime < _now ? "upcoming" : "active";
              return {
                decimals: 18,
                name: "",
                symbol: "",
                poolAddress: value.poolAddress,
                poolState: _poolState,
                poolDetails: value.poolDetails,
                startTime: _startTime,
                token: value.token,
                totalAllocationAmount: Number(
                  formatUnits(value.totalAllocationAmount, 18)
                ),
                totalClaimedAmount: Number(
                  formatUnits(value.totalClaimedAmount, 18)
                ),
                logourl: value.poolDetails.toString().split("$#$")[0],
                bannerurl: value.poolDetails.toString().split("$#$")[1],
                owner: value.createdBy,
              };
            })
          ).then((result) => {
            setStats({
              getTotalNumberOfPools: _airdrops.length - 1,
              airdropList: result,
              page: page,
              pageSize: pageSize,
              loading: !loading,
              chainId: _chainId_,
            });
          });
        } else {
          setStats({
            getTotalNumberOfPools: 0,
            page: page,
            pageSize: pageSize,
            airdropList: [],
            loading: false,
            chainId: _chainId_,
          });
        }
      } catch (err) {
        toast.error(err.reason);
        history.push("/");
      }
    };

    if (apolloClient) {
      fetch();
    } else {
      setStats({
        getTotalNumberOfPools: 0,
        page: page,
        pageSize: pageSize,
        airdropList: [],
        loading: false,
        chainId: _chainId_,
      });
    }
    // eslint-disable-next-line
  }, [updater, _chainId_, type]);

  return stats;
};
