import React, { useContext, useState } from "react";
import Context from "./context/Context";
import { toast } from "react-toastify";
import Button from "react-bootstrap-button-loader";
import { contract } from "../../../hooks/constant";
import airdropFactoryAbi from "../../../json/AirdropFactory.json";
import { getContract } from "../../../hooks/contractHelper";
import { useWeb3React } from "@web3-react/core";
import { getWeb3 } from "../../../hooks/connectors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { parseEther } from "ethers/lib/utils";

export default function Step2() {
  const { value, btnPrevStep, setValue } = useContext(Context);
  const { account, library, chainId } = useWeb3React();
  const history = useHistory();
  const [error, setError] = useState({
    logourl: "",
    bannerurl: "",
    website: "",
    facebook: "",
    twitter: "",
    github: "",
    telegram: "",
    instagram: "",
    discord: "",
    reddit: "",
    youtube: "",
    brief: "",
    blockstart: "",
  });

  const [loading, setLoading] = useState(false);

  const checkValidation = (input, inputValue) => {
    let terror = 0;
    let message = "";
    var reg;
    switch (input) {
      case "facebook":
      case "twitter":
      case "github":
      case "telegram":
      case "instagram":
      case "discord":
      case "reddit":
      case "youtube":
      case "blockstart":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (inputValue !== "" && !reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter Valid url!";
        } else {
          message = "";
        }
        break;

      case "logourl":
      case "bannerurl":
      case "website":
        reg = new RegExp(
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
        );
        if (!reg.test(inputValue)) {
          terror += 1;
          message = "Please Enter Valid url!";
        } else {
          message = "";
        }
        break;
      default:
        terror += 0;
        break;
    }

    if (terror > 0) {
      setError({ ...error, [input]: message });
      return false;
    } else {
      setError({ ...error, [input]: "" });
      return true;
    }
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    checkValidation(e.target.name, e.target.value);
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleCreateSale = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (account) {
        if (chainId) {
          let para = [
            value.tokenAddress,
            `${value.logourl}$#$${value.bannerurl}$#$${value.website}$#$$#$${value.facebook}$#$${value.twitter}$#$${value.github}$#$${value.telegram}$#$${value.instagram}$#$${value.discord}$#$${value.reddit}$#$${value.youtube}$#$${value.brief}`,
          ];

          let airdropfactoryAddress = contract[chainId]
            ? contract[chainId].airdropFactory
            : contract["default"].airdropFactory;
          let factoryContract = getContract(
            airdropFactoryAbi,
            airdropfactoryAddress,
            library
          );

          let feesCal = 0;

          let tx = await factoryContract.createAirdrop(para[0], para[1], {
            from: account,
            value: parseEther(feesCal.toFixed(8).toString()),
          });

          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 10000)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Waiting for confirmation 👌",
          });

          var interval = setInterval(async function () {
            let web3 = getWeb3(chainId);
            var response = await web3.eth.getTransactionReceipt(tx.hash);
            if (response != null) {
              clearInterval(interval);
              if (response.status === true) {
                toast.success("success ! your last transaction is success 👍");
                setLoading(false);
                if (typeof response.logs[0] !== "undefined") {
                  history.push(`/airdrop-details/${response.logs[0].address}`);
                } else {
                  toast.error("something went wrong !");
                  history.push("/");
                }
              } else if (response.status === false) {
                toast.error("error ! Your last transaction is failed.");
                setLoading(false);
              } else {
                toast.error("error ! something went wrong.");
                setLoading(false);
              }
            }
          }, 5000);
        } else {
          toast.error("wrong network selected !");
          setLoading(false);
        }
      } else {
        toast.error("Please Connect Wallet!");
        setLoading(false);
      }
    } catch (err) {
      toast.error(err.reason ? err.reason : err.message);
      setLoading(false);
    }
  };

  return (
    <div
      className={`tab-pane ${value.step === 2 ? "active" : ""}`}
      role="tabpanel"
      id="step2"
    >
      <h4 className="text-center">Let people know who you are</h4>
      <div className="row">
        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>
              Logo URL<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="logourl"
              placeholder="e.g. https://slapameme.com/logo.png"
            />
            <small className="text-danger">{error.logourl}</small>
            <br />
          </div>
        </div>
        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>
              Banner Image URL<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="bannerurl"
              placeholder="e.g. https://slapameme.com/banner.jpg"
            />
            <small className="text-danger">{error.bannerurl}</small>
            <br />
          </div>
        </div>
        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>
              Website*<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="website"
              placeholder="e.g. https://slapameme.com/"
            />
            <small className="text-danger">{error.website}</small>
            <br />
          </div>
        </div>

        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>Facebook</label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="facebook"
              placeholder="e.g. https://www.facebook.com/"
            />
            <small className="text-danger">{error.facebook}</small>
            <br />
          </div>
        </div>
        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>Twitter</label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="twitter"
              placeholder="e.g. https://twitter.com/"
            />
            <small className="text-danger">{error.twitter}</small>
            <br />
          </div>
        </div>

        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>Github</label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="github"
              placeholder="e.g. https://github.com/"
            />
            <small className="text-danger">{error.github}</small>
            <br />
          </div>
        </div>
        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>Telegram</label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="telegram"
              placeholder="e.g. https://t.me/BlockStar_Social_Media"
            />
            <small className="text-danger">{error.telegram}</small>
            <br />
          </div>
        </div>

        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>Instagram</label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="instagram"
              placeholder="e.g. https://www.instagram.com/"
            />
            <small className="text-danger">{error.instagram}</small>
            <br />
          </div>
        </div>
        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>Discord</label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="discord"
              placeholder="e.g. https://discord.com/"
            />
            <small className="text-danger">{error.discord}</small>
            <br />
          </div>
        </div>

        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>Reddit</label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="reddit"
              placeholder="e.g. https://reddit.com/"
            />
            <small className="text-danger">{error.reddit}</small>
            <br />
          </div>
        </div>
        <div className="col-md-6 mt-4 mb-0">
          <div className="form-group">
            <label>Youtube Video</label>
            <input
              className="form-control"
              onChange={(e) => onChangeInput(e)}
              type="text"
              name="youtube"
              placeholder="e.g. https://www.youtube.com/watch?v=BHACKCNDMW8"
            />
            <small className="text-danger">{error.youtube}</small>
            <br />
          </div>
        </div>

        <div className="col-md-12 mt-4 mb-0">
          <div className="form-group">
            <label>Description</label>
            <textarea
              type="text"
              name="brief"
              onChange={(e) => onChangeInput(e)}
              className="brief"
              placeholder="Project Brief"
              value={value.brief}
            ></textarea>
            <small className="text-danger">{error.brief}</small>
            <br />
          </div>
        </div>
      </div>
      <ul className="list-inline text-center">
        <li>
          <button
            type="button"
            className="btn default-btn prev-step mr-4"
            onClick={(e) => btnPrevStep(e)}
          >
            Back
          </button>
        </li>
        <li>
          <Button
            type="button"
            className="btn default-btn next-step"
            onClick={(e) => handleCreateSale(e)}
            loading={loading}
          >
            Create New Airdrop
          </Button>
        </li>
      </ul>
    </div>
  );
}
