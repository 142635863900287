import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import airdropAbi from "../../../../json/Airdrop.json";
import {
  MulticallContractWeb3,
  multiCallContractConnect,
} from "../../../../hooks/useContracts";
import { getWeb3 } from "../../../../hooks/connectors";
import tokenAbi from "../../../../json/token.json";
import { toast } from "react-toastify";
import { getChainInfo } from "../../../../hooks/network";
import { formatUnits } from "ethers/lib/utils";

export const useCommonStats = (update) => {
  const context = useWeb3React();
  const { chainId } = context;
  const location = useLocation();

  let urlAddress = location.pathname.split("/").pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainId");

  const { _chainId_ } = getChainInfo(queryChainId, chainId);

  let web3 = getWeb3(_chainId_);

  let poolContract = new web3.eth.Contract(airdropAbi, urlAddress);

  const [stats, setStats] = useState({
    startTime: 0,
    poolDetails: 0,
    tge: 0,
    releasePercent: 0,
    cycle: 0,
    owner: "",
    token: 0,
    tokenName: "",
    tokenDecimal: 0,
    tokenSymbol: "",
    tokenSupply: 0,
    poolAddress: "",
    totalAllocation: 0,
    totalClaimed: 0,
    allocations: [],
  });

  const mc = MulticallContractWeb3(_chainId_);

  useEffect(() => {
    try {
      let poolAddress = web3.utils.isAddress(urlAddress);
      let isCode = web3.eth.getCode(urlAddress);
      if (isCode === "0x" || !poolAddress) {
        return;
      }
    } catch (err) {
      return;
    }

    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          poolContract.methods.startTime(), //0
          poolContract.methods.detail(), //1
          poolContract.methods.tge(), //2
          poolContract.methods.releasePercent(), //3
          poolContract.methods.cycle(), //4
          poolContract.methods.token(), //5
          poolContract.methods.owner(), //6
          poolContract.methods.totalAllocationAmount(),
          poolContract.methods.totalClaimedAmount(),
          poolContract.methods.getAllocations(),
        ]);

        let tokenContract = new web3.eth.Contract(tokenAbi, data[5]);

        const tokendata = await mc.aggregate([
          tokenContract.methods.name(),
          tokenContract.methods.decimals(),
          tokenContract.methods.symbol(),
          tokenContract.methods.totalSupply(),
        ]);

        setStats({
          startTime: data[0],
          poolDetails: data[1],
          tge: data[2],
          releasePercent: data[3],
          cycle: data[4],
          token: data[5],
          owner: data[6],
          totalAllocation: data[7],
          totalClaimed: data[8],
          allocations: data[9],
          tokenName: tokendata[0],
          tokenDecimal: tokendata[1],
          tokenSymbol: tokendata[2],
          tokenSupply: tokendata[3] / Math.pow(10, tokendata[1]),
          poolAddress: urlAddress,
        });
      } catch (err) {
        toast.error("wrong network selected !");
        // history.push("/sale-list");
      }
    };

    if (mc) {
      fetch();
    } else {
      setStats({
        startTime: 0,
        poolDetails: 0,
        tge: 0,
        releasePercent: 0,
        cycle: 0,
        token: 0,
        tokenName: "",
        tokenDecimal: 0,
        tokenSymbol: "",
        tokenSupply: 0,
        poolAddress: "",
        totalAllocation: 0,
        totalClaimed: 0,
        allocations: [],
      });
    }
    // eslint-disable-next-line
  }, [update, chainId]);

  return stats;
};

export const useAccountStats = (updater) => {
  const context = useWeb3React();
  const { chainId, account } = context;
  const location = useLocation();
  let urlAddress = location.pathname.split("/").pop();
  const search = useLocation().search;
  const queryChainId = new URLSearchParams(search).get("chainId");

  const { _chainId_ } = getChainInfo(queryChainId, chainId);

  let web3 = getWeb3(_chainId_);

  const [stats, setStats] = useState({
    ethBalance: 0,
    allowance: 0,
    tokenBalance: 0,
  });

  let poolContract = new web3.eth.Contract(airdropAbi, urlAddress);

  const mc = MulticallContractWeb3(_chainId_);
  const mcc = multiCallContractConnect(_chainId_);

  useEffect(() => {
    try {
      let poolAddress = web3.utils.isAddress(urlAddress);
      let isCode = web3.eth.getCode(urlAddress);
      if (isCode === "0x" || !poolAddress) {
        return;
      }
    } catch (err) {
      return;
    }
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          mcc.methods.getEthBalance(account),
          poolContract.methods.token(),
        ]);

        let tokenContract = new web3.eth.Contract(tokenAbi, data[1]);

        const tokenData = await mc.aggregate([
          tokenContract.methods.decimals(),
          tokenContract.methods.allowance(account, urlAddress),
          tokenContract.methods.balanceOf(account),
        ]);

        setStats({
          ethBalance: data[0] / Math.pow(10, 18),
          allowance: formatUnits(tokenData[1], tokenData[0]),
          tokenBalance: formatUnits(tokenData[2], tokenData[0]),
          allocations: data[2],
          totalAllocation: data[3],
          totalClaimed: data[4],
        });
      } catch (err) {
        toast.error(err.reason);
        // history.push('/sale-list');
      }
    };

    if (mc && account) {
      fetch();
    } else {
      setStats({
        ethBalance: 0,
        allowance: 0,
        tokenBalance: 0,
        totalAllocation: 0,
        totalClaimed: 0,
        allocations: [],
      });
    }
    // eslint-disable-next-line
  }, [account, updater, chainId]);

  return stats;
};
